<template lang="pug">
  v-container
    h1.mb-3 Правила обработки персональных данных в автоматизированной информационной системе
      |  «персонифицированное финансирование дополнительного образования»

    p (для неограниченного доступа, опубликовано в соответствии с ч.2 ст.18.1 Федерального закона
      |  от 27 июля 2006 г. N 152-ФЗ "О персональных данных")

    p.text-center.mt-4.font-weight-bold 1. Общие положения
    p 1.1. Настоящие Правила определяют порядок обработки персональных данных и меры по обеспечению безопасности персональных
      |  данных в автоматизированной информационной системе «персонифицированное финансирование дополнительного образования»
      |  (далее – Система), получаемые от физических лиц в процессе использования Системы (далее – Пользователи),
      |  и предоставляемых из сторонних сервисов, а также является пользовательским соглашением,
      |  закрепляющим правовой статус отношений, возникающих вследствие использования Системы.
    p 1.2. Реализацию Правил осуществляет ООО "Институт развития персонифицированных систем управления" (далее – Оператор) в
      |  отношении обработки и обеспечения защиты персональных данных физических лиц (субъектов персональных данных) на основании
      |  статьи 24 Конституции РФ и Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» (далее – ФЗ-152).
    p 1.3. Действие Правил распространяется на любую информацию, относящуюся к прямо или косвенно определенному или
      |  определяемому физическому лицу (субъекту персональных данных), полученную Оператором в определяемых настоящими
      |  Правилами целях (далее - Данные), полученную как до, так и после утверждения настоящих Правил.
    p 1.4. В настоящих Правилах используются термины и определения в соответствии с их значениями, как они определены в ФЗ-152.
    p 1.5. Действие Правил распространяется на все персональные данные Пользователей,
      |  обрабатываемые Оператором с использованием Системы.
    p 1.6. Правила распространяются на всех сотрудников Оператора (включая работников по трудовым договорам и сотрудников,
      |  работающих по договорам подряда) и все структурные подразделения Оператора, включая обособленные подразделения.
      |  Требования Правил также учитываются и предъявляются в отношении иных лиц при необходимости их участия в процессе
      |  обработки персональных данных Оператора, а также в случаях передачи им в установленном порядке персональных данных
      |  на основании соглашений, договоров, поручений на обработку.
    p 1.7. Правила являются общедоступным документом Оператора и предусматривает возможность ознакомления с ней в сети Интернет
      |  по адресу:
      router-link.primary--text(:to="{ name: 'information-policy' }")  {{ policyLink }}
    p 1.8. Пользователи, используя Систему, в том числе отдельные сервисы и услуги, размещенные в Системе, сообщив Оператору
      |  свои персональные данные, персональные данные детей, родителями (законными представителями) которых они являются в том
      |  числе при посредничестве третьих лиц, дают своё согласие Оператору и иным лицам, действующим по поручению Оператора на
      |  основании договора и в интересах Субъекта персональных данных, на обработку персональных данных в соответствии
      |  с настоящими Правилами. В случае несогласия с настоящими Правилами в целом, как и в случае несогласия с каким-либо
      |  пунктом настоящих Правил, Пользователь должен воздержаться от использования Системы.
    p 1.9. Оператор получает и начинает обработку персональных данных Субъекта персональных данных с момента получения
      |  его согласия либо согласия его родителя (законного представителя). Согласие на обработку персональных данных может
      |  быть дано Субъектом персональных данных (его родителем (законным представителем)) в любой форме, позволяющей
      |  подтвердить факт получения согласия, если иное не установлено федеральным законом: в письменной, устной или иной
      |  форме, предусмотренной действующим законодательством, в том числе посредством совершения Субъектом персональных
      |  данных конклюдентных действий при использовании сервисов Системы, использовании форм обратной связи и акцепте оферт,
      |  содержащих в себе положения об обработке персональных данных в соответствии с действующим законодательством.
    p 1.10. Согласие на обработку персональных данных может быть отозвано субъектом персональных данных.
    p 1.11. Настоящие Правила могут быть изменены Оператором. Оператор имеет право в любое время по своему собственному усмотрению
      |  вносить изменения в настоящие Правила. При внесении изменений в актуальной редакции указывается дата последнего
      |  обновления. Новая редакция Правил вступает в силу с момента ее размещения на веб-сервере, если иное не предусмотрено
      |  новой редакцией Правил. При этом с момента такого размещения согласие субъекта персональных данных с новой версией
      |  Правил считается полученным Оператором, впредь до получения Оператором информации от Субъекта персональных данных
      |  об обратном.
    p 1.12. Настоящие Правила применимы к информации о Пользователе, получаемой в ходе использования Системы, а также к информации
      |  о Субъектах персональных данных, получаемой в целях организации персонифицированного учета сертификатов дополнительного
      |  образования в соответствии с заявлениями Субъектов персональных данных (их родителей (законных представителей)),
      |  подаваемых в целях предоставления сертификата дополнительного образования и регистрации в реестре сертификатов
      |  дополнительного образования им (его родителями (законными представителями).
    p 1.13. Оператор не контролирует и не несет ответственности за обработку информации, предоставляемой Пользователями
      |  веб-сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на официальном веб-сервере Системы.
    p 1.14. Третьи лица, привлекаемые Оператором в целях организации доступа Пользователей к Системе и иных случаях,
      |  когда это необходимо для нормального функционирования Системы, при необходимости обработки Персональных данных
      |  обрабатывают их с учетом положений настоящих правил.

    p.text-center.mt-4.font-weight-bold 2. Цели обработки персональных данных
    p 2.1. Оператор обрабатывает персональные данные Пользователя в целях обеспечения получения дополнительного образования
      |  посредством механизма персонифицированного финансирования дополнительного образования детей, в том числе:
    p 2.1.1. Идентификации Субъекта персональных данных и предоставления доступа к Системе и всем ее сервисам;
    p 2.1.2. направление Пользователю  сообщений с использованием сервисов Системы и сообщений по электронной почте,
      |  уведомлений, запросов;
    p 2.1.3. предоставление Пользователю сервисов формирования и подачи заявок на обучение по образовательным программам;
    p 2.1.4. предоставления пользователям Системы сервисов по формированию и акцептированию договоров-оферт
      |  на обучение по образовательным программам;
    p 2.1.5. формирования рекомендаций для Пользователя по выбору образовательных программ для освоения, мероприятий для участия;
    p 2.1.6. предоставления сервисов оплаты образовательных услуг;
    p 2.1.7. проведение проверки, исследований и анализа данных, позволяющих поддерживать и улучшать сервисы и разделы
      |  Системы, а также разрабатывать новые сервисы и разделы Системы;
    p 2.1.8. проведение статистических и иных исследований на основе обезличенных данных;
    p 2.1.9. выполнение полномочий и обязанностей, возложенных на Оператора законодательством Российской Федерации;
    p 2.1.10. для других целей с согласия Пользователя.
    p 2.2. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности,
      |  политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.

    p.text-center.mt-4.font-weight-bold 3. Принципы обработки персональных данных
    p 3.1. Обработка персональных данных в Компании осуществляется на основе следующих принципов:
    p 3.1.1. законности и справедливой основы;
    p 3.1.2. ограничения обработки персональных данных достижением конкретных, заранее определённых и законных целей;
    p 3.1.3. недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;
    p 3.1.4. недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях,
      |  несовместимых между собой;
    p 3.1.5. обработки только тех персональных данных, которые отвечают целям их обработки;
    p 3.1.6. соответствия содержания и объёма обрабатываемых персональных данных заявленным целям обработки;
    p 3.1.7. недопущения обработки избыточных персональных данных по отношению к заявленным целям их обработки;
    p 3.1.8. обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки
      |  персональных данных;
    p 3.1.9. уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты
      |  необходимости в достижении этих целей, при невозможности устранения Компанией допущенных нарушений персональных
      |  данных, если иное не предусмотрено федеральным законом.
    p 3.2. Работники Организации, допущенные к Обработке Персональных данных, обязаны:
    p 3.2.1. Знать и неукоснительно выполнять положения:
    ul
      li законодательства Российской Федерации в области персональных данных, настоящих Правил, локальных актов Оператора
        |  по вопросам Обработки и обеспечения безопасности персональных данных;
      li обрабатывать персональные данные только в рамках выполнения своих должностных обязанностей;
      li не разглашать персональные данные, обрабатываемые Оператором;
      li сообщать о действиях других лиц, которые могут привести к нарушению положений настоящих Правил;
      li сообщать об известных фактах нарушения требований настоящих Правил лицу, ответственному за организацию обработки
        |  персональных данных.

    p.text-center.mt-4.font-weight-bold 4. Условия обработки персональных данных
    p 4.1. Оператор обрабатывает персональные данные только при наличии хотя бы одного из следующих условий:
    p 4.1.1. обработка персональных данных осуществляется с согласия Пользователя на обработку его персональных данных;
    p 4.1.2. обработка персональных данных необходима для достижения целей, предусмотренных законом, для осуществления и
      |  выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;
    p 4.1.3. обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем
      |  или поручителем по которому является Пользователь, а также для заключения договора по инициативе Пользователя
      |  или договора, по которому Пользователь будет являться выгодоприобретателем или поручителем;
    p 4.1.4. обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц
      |  либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Пользователя;
    p 4.1.5. осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
      |  предоставлен Пользователем либо по его просьбе;
    p 4.1.6. осуществляется обработка персональных данных, подлежащих опубликованию или обязательному
      |  раскрытию в соответствии с федеральным законом.

    p.text-center.mt-4.font-weight-bold 5. Сроки обработки персональных данных
    p 5.1. Персональные данные обрабатываются субъектом с момента получения согласия на обработку персональных данных
      |  в соответствии с настоящими Правилами и до исключения Субъекта персональных данных из реестра сертификатов
      |  соответствии действующим законодательством.
    p 5.2. Персональные данные хранятся до прекращения их обработки.
    p 5.3. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных
      |  данных согласно условиям использования сертификата дополнительного образования, в том числе достижение Субъектом
      |  персональных данных предельного возраста использования сертификата дополнительного образования, или отзыв согласия
      |  субъекта персональных данных (его родителей (законных представителей) на обработку его персональных данных.

    p.text-center.mt-4.font-weight-bold 6. Категории пользователей, персональные данные которых обрабатываются Оператором
    p 6.1. Оператором осуществляется обработка полученных в установленном порядке персональных данных детей от 5 до 18 лет,
      |  внесенных в установленном порядке в реестр сертификатов дополнительного образования детей,
      |  а также их родителей (законных представителей)
    p 6.2. Перечень персональных данных, обрабатываемых Оператором в Системе:
    p 6.2.1. фамилия, имя, отчество (при наличии);
    p 6.2.2. дата рождения ребенка;
    p 6.2.3. место (адрес) проживания ребенка;
    p 6.2.4. фамилия, имя, отчество (при наличии) родителя (законного представителя) ребенка;
    p 6.2.5. контактная информация родителя (законного представителя) ребенка, в том числе номер телефона
      |  и адрес электронной почты.
    p 6.2.6. Иные данные, с согласия Субъекта персональных данных в случае необходимости для достижения
      |  целей обработки персональных данных.

    p.text-center.mt-4.font-weight-bold 7. Права Пользователей
    p 7.1. Пользователь имеет право на получение информации, касающейся обработки его персональных данных,
      |  персональных данных детей, родителем (законным представителем) которых он является, в том числе содержащей:
    p 7.1.2. подтверждение факта обработки персональных данных Оператором;
    p 7.1.3. правовые основания и цели обработки персональных данных;
    p 7.1.4. цели и применяемые Оператором способы обработки персональных данных;
    p 7.1.5. наименование и место нахождения Оператора, сведения о лицах (за исключением работников Оператора),
      |  которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании
      |  договора с Оператором или на основании федерального закона;
    p 7.1.6. обрабатываемые персональные данные, относящиеся к соответствующему Пользователю, источник их получения,
      |  если иной порядок представления таких данных не предусмотрен федеральным законом;
    p 7.1.7. сроки обработки персональных данных, в том числе сроки их хранения;
    p 7.1.8. наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных
      |  по поручению Оператора, в случае если обработка поручена или будет поручена такому лицу;
    p 7.1.9. иные сведения, предусмотренные 152-ФЗ или другими федеральными законами.
    p 7.2. Право Пользователя на получение информации, касающейся обработки его персональных данных, может быть
      |  ограничено в случаях, установленных 152-ФЗ.
    p 7.3. Согласие на обработку персональных данных может быть отозвано Пользователем. В случае отзыва
      |  Пользователем согласия на обработку персональных данных Оператор вправе продолжить обработку персональных
      |  данных без согласия Пользователя при наличии оснований, указанных в 152-ФЗ.
    p 7.4. Пользователь имеет также иные права, установленные 152-ФЗ.
    p 7.5. Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения
      |  в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или
      |  не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры
      |  по защите своих прав.
    p 7.6. Пользователь имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков
      |  и (или) компенсацию морального вреда в судебном порядке.

    p.text-center.mt-4.font-weight-bold 8. Круг лиц, допущенных к обработке персональных данных.

    p 8.1. Для достижения целей обработки персональных данных к ней допускаются допущены только те сотрудники Оператора,
      |  на которых возложена такая обязанность в соответствии с их служебными (трудовыми) обязанностями. Доступ других
      |  сотрудников может быть предоставлен только в предусмотренных законом случаях. Оператор требует от своих сотрудников
      |  соблюдения конфиденциальности и обеспечения безопасности персональных данных, при их обработке.
    p 8.2. В целях сопровождения функционирования Системы и отдельных сервисов Системы Пользователь соглашается с передачей
      |  предоставляемых им персональных данных третьим лицам, указанным в пункте 1.14.По мотивированному запросу уполномоченного
      |  органа и согласно действующему законодательству персональные данные субъекта без его согласия могут быть переданы:
    ul
      li в связи с осуществлением правосудия в судебные органы;
      li в органы полиции, федеральной службы безопасности, прокуратуры, следственного комитета;
      li в иные уполномоченные действующим законодательством и применимыми нормами права органы и организации в случаях,
        |  установленных в нормативно-правовых актах, обязательных для исполнения оператором.

    p.text-center.mt-4.font-weight-bold 9. Порядок и методы обработки персональных данных.
    p 9.1. В процессе персонифицированного учета в системе дополнительного образования детей Оператор использует
      |  автоматизированную обработку персональных данных с использованием сервисов, предоставляемых Системой».
    p 9.2. Оператор вправе поручить обработку персональных данных другому лицу с согласия Субъекта персональных данных
      |  (его родителя (законного представителя)), если иное не предусмотрено законодательством Российской Федерации,
      |  при условии соблюдения этим лицом принципов и правил обработки персональных данных, предусмотренных Федеральным
      |  законом «О персональных данных» и настоящими Правилами.
    p 9.3. Персональные данные не раскрываются третьим лицам и не распространяются иным образом без согласия Субъекта
      |  персональных данных (его родителя (законного представителя)), если иное не предусмотрено законодательством
      |  Российской Федерации.
    p 9.4. Представители органов государственной власти, органов местного самоуправления муниципальных районов
      |  (городских округов), уполномоченные ими организации получают доступ к персональным данным, обрабатываемым
      |  Оператором, в объеме и порядке, установленном законодательством Российской Федерации, нормативными правовыми
      |  актами, регламентирующими систему персонифицированного дополнительного образования детей.
    p 9.5. В случае подтверждения факта неточности персональных данных или неправомерности их обработки,
      |  персональные данные подлежат их актуализации, а обработка должна быть прекращена.

    p.text-center.mt-4.font-weight-bold 10. Меры защиты
    p 10.1. Оператор предпринимает необходимые правовые, организационные и технические меры для обеспечения безопасности
      |  персональных данных для их защиты от несанкционированного (в том числе, случайного) доступа, уничтожения, изменения,
      |  блокирования доступа и других несанкционированных действий в соответствии с действующим
      |  законодательством РФ. К таким мерам, в частности, относятся:
    p 10.1.1. назначение сотрудников, ответственных за организацию обработки и обеспечение безопасности персональных данных;
    p 10.1.2. проверка наличия в договорах и включение при необходимости в договоры пунктов об обеспечении
      |  конфиденциальности персональных данных;
    p 10.1.3. использование аттестованных прошедших процедуру оценки соответствия в установленном порядке серверов,
      |  обеспечивающих функционирование Системы и отдельных ее сервисов, средств обеспечения безопасности (антивирусных средств,
      |  межсетевых экранов, средств защиты от несанкционированного доступа, средств криптографической защиты информации);
    p 10.1.4. издание локальных актов по вопросам обработки персональных данных, ознакомление с ними Пользователей;
    p 10.1.5. ограничение и разграничение доступа сотрудников и иных лиц к персональным данным и средствам обработки;
    p 10.1.6. учёт и хранение носителей информации, исключающее их хищение, подмену, несанкционированное копирование
      |  и уничтожение;
    p 10.1.7. резервное копирование информации для возможности восстановления;
    p 10.1.8. осуществление внутреннего контроля за соблюдением установленного порядка, проверка эффективности принятых мер,
      |  реагирование на инциденты.
    p 10.1.9. устанавливаются правила доступа к обрабатываемым персональным данным, а также обеспечивается регистрация
      |  и учет действий, совершаемых с персональными данными;
    p 10.2. Деятельность Оператора по обработке персональных данных в Системе неразрывно связана с защитой Оператором
      |  конфиденциальности полученной информации. Все работники Оператора обязаны обеспечивать конфиденциальность
      |  персональных данных, если это не противоречит действующему законодательству РФ.

    p.text-center.mt-4.font-weight-bold 11. Условия использования Системы.
    p 11.1. Настоящие Правила могут быть приняты исключительно в целом (п.1. ст. 428 ГК РФ). После принятия условий
      |  настоящих Правил, они приобретает силу договора, заключенного между Оператором (иными лицами,
      |  ответственными за функционирование Системы в силу договора с Оператором) и пользователями.
    p 11.2. Пользователи соглашаются, что в настоящие Правила могут быть внесены изменения и принимают на себя
      |  обязательство отслеживать внесенные изменения, опубликованные на
      |  router-link.primary--text(:to="{ name: 'information-policy' }")  {{ policyLink }}
    p 11.3. Оператор обязуется:
    p 11.3.1. Воздерживаться от каких-либо действий, способных затруднить осуществление пользователями предоставленного
      |  права использования Системы в установленных настоящими Правилами пределах.
    p 11.3.2. Предоставлять необходимую информацию по вопросам работы с Системой.
    p 11.3.3. Оказывать пользователям техническую поддержку. Сложность, объем, вид и сроки оказания такой поддержки
      |  определяются Оператором в зависимости от сложности конкретной ситуации.
    p 11.4. Пользователи обязуются:
    p 11.4.1. Использовать Систему только в пределах своих прав и в тех объемах, которые предусмотрены настоящими
      |  Правилами и иными соглашениями.
    p 11.4.2. Хранить в тайне и не раскрывать третьим лицам информацию о своем пароли и иные сведения, которые могут
      |  потребоваться при получении доступа к личным кабинетам. В случае, если такие данные будут скомпрометированы,
      |  незамедлительно уведомить Оператора и принять меры к изменению пароля.
    p 11.4.3. Воздерживаться от копирования в любой форме и распространения размещенной в Системе информации,
      |  изображений и иных данных, а также от создания на их основе иных производных объектов без письменного согласия.
    p 11.5. Оператор вправе:
    p 11.5.1. Приостановить или прекратить доступ пользователей на сайт в случае наличия оснований полагать
      |  о ведении пользователями неправомерной деятельности.
    p 11.5.2. Временно прекращать или приостанавливать работу системы в полном объеме либо в части в целях
      |  необходимого технического обслуживания. При этом пользователи не вправе требовать возмещения каких-либо убытков
      |  и ущерба, вызванного указанным прекращением или приостановкой работы системы.
    p 11.6. Пользователи и оператор обязуются принимать меры по решению всех споров в досудебном порядке.
      |  В случае недостижения согласия спор передается на рассмотрение в суд по месту нахождения Оператора или Ответчика.
      |  При этом претензионный порядок является обязательным. Срок рассмотрения претензии – 10 календарных дней.
    p 11.7. Вопросы, неурегулированные настоящими Правилами, подлежат разрешению в соответствии с законодательством РФ

</template>

<script>
export default {
  name: 'AboutPersonalData',
  data() {
    return {
      policyLink: null,
    };
  },
  mounted() {
    this.policyLink = window.location.href;
  },
};
</script>

<style scoped lang="scss">

  p, li {
    font-size: 16px;
  }

</style>
